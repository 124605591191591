import React from "react"
import styled from "styled-components"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import { MainWrapper } from "components"

const MenuWrapper = styled.section`
  margin: 70px 0;
`

const Content = styled.div`
  margin: 60px 0px 30px;

  .text-center {
    display: flex;
    justify-content: center;
  }

  .menu {
    > div {
      padding: 0 40px;
    }
  }
`

const Menus = styled.div`
  padding-top: 70px;

  .name {
    h1 {
      margin-bottom: 20px;
      font-family: var(--fontSecondary);
      font-size: 25px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .items {
    padding: 20px 0;
    border-bottom: 1px solid rgba(253, 197, 35, 0.27058823529411763);

    .item-name {
      font-weight: bold;
    }

    .item-price {
      float: right;
    }

    .item-des {
      font-size: 13px;
      margin-top: 10px;
    }
  }

  .item-ul,
  .options {
    font-size: 13px;
    margin-top: 10px;
    padding-left: 30px;

    span {
      padding: 10px 0;
    }
  }

  .item-ul li {
    list-style: circle;
    padding: 5px 0;
  }

  h6 {
    font-size: 15px;
    padding: 10px 0 7px 0;
  }
`

const MenuPage = ({ children, data }) => (
  <MenuWrapper>
    <MainWrapper>
      <Content>
        {children}
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 768: 2 }}>
          <Masonry className="menu">
            {data.allInternalResData.nodes.map(menus => (
              <Menus key={menus.id}>
                <div className="name">
                  <h1>{menus.name}</h1>
                  {menus.description && <p>{menus.description}</p>}
                </div>
                <div className="items-groups">
                  {menus.items.map(item => (
                    <div className="items" key={item.id}>
                      <div className="main">
                        <span className="item-name">{item.name}</span>
                        {item.price === 0 ? null : (
                          <span className="item-price">{`$${item.price}`}</span>
                        )}
                        {item.description && (
                          <p className="item-des">{item.description}</p>
                        )}
                      </div>
                      {item.sizes.length === 0 ? null : (
                        <ul className="item-ul">
                          <h6>Sizes:</h6>
                          {item.sizes.map(size => (
                            <li key={size.menu_item_id}>
                              {size.name}{" "}
                              {size.price === 0 ? null : (
                                <span>- ${size.price.toFixed(2)}</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                  {menus.groups.length !== 0 && (
                    <div className="options">
                      {menus.groups.map(group => (
                        <ul key={group.menu_id} className="item-ul">
                          <span>
                            <h6>{group.name}</h6>
                          </span>
                          {group.options.map(option => (
                            <li key={option.id}>
                              {option.name}
                              {option.price === 0 ? null : (
                                <> - ${option.price.toFixed(2)}</>
                              )}
                            </li>
                          ))}
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </Menus>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Content>
    </MainWrapper>
  </MenuWrapper>
)

export default MenuPage
